import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { useFormContext } from "react-hook-form";
import "./ShipMethod.scss";
import Loading from "../../components/Loading/loading";

export const listTransport = [
  {
    id: 'NORMAL',
    title: "Giao tại nhà",
    titlePrint: "Giao tại nhà",
  },
  {
    id: 'FASTER',
    title: "Giao hoả tốc",
    titlePrint: "Giao hoả tốc",
  },
  {
    id: 'IN_SHOP',
    title: "Đến cửa hàng lấy",
    titlePrint: "Đến cửa hàng lấy",
  },
  {
    id: 'SHOP_SUGGESTION',
    title: "Thương lượng (LH: 0903760903)",
    titlePrint: "Thương lượng",
  },
]

export const INVOICE_TYPE = {
  DIRECT: 'DIRECT',
  E_VAT_INVOICE: 'E_VAT_INVOICE',
}
const ShipMethod = (props) => {
  const { isLoadingTypeDelivery, status } = props;
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext();
  const [validate, setValidate] = useState(false);

  useEffect(() => {
    if (status === "INFO") {
      setValidate(true);
    } else {
      setValidate(false);
    }
  }, [status]);

  return (
    <div className="ship-method">
      <h5>Phương thức vận chuyển</h5>
      <Row>
        <Col col={12} md={6}>
          {listTransport.map((item) =>
            <Row key={item.id} className="justify-content-start align-items-center mb-3">
              <input
                {...register("typeDelivery", {
                  required: 'Phương thức vận chuyển không được để trống',
                })}
                value={item.id}
                disabled={validate}
                className="col-md-2"
                type="radio"
              />
              <label className="m-0 col-md-7">
                {item.title}
              </label>

            </Row>
          )}

          {errors.typeDelivery && (
            <p style={{ paddingLeft: 32 }} className="p-errors">
              {errors.typeDelivery.message}
            </p>
          )}
        </Col>
        <Col col={12} md={6}>

          <label className="col-md-8 mb-3">
            Phí vận chuyển( tạm tính ): {
              <input
                disabled={validate || isLoadingTypeDelivery}
                {...register("costDeliver", {
                  required: 'Phí vận chuyển không được để trống',
                })}
                type='number'
              />
            } {isLoadingTypeDelivery && (
              <Loading
                height={16}
                width={16}
                className="loading-custom-primary"
                type="spinningBubbles"
              />
            )}
          </label>
          {errors.costDeliver && (
            <p style={{ paddingLeft: 32 }} className="p-errors">
              {errors.costDeliver.message}
            </p>
          )}
          <div>
            <label className="col-md-8 mb-3">
              Tiền ứng trước:
              <input
                disabled={validate}
                {...register("deposit")}
                type='number'
              />
            </label>
          </div>
          <div>Khách hàng nhập mã miễn phí vận chuyển: {getValues().codeDiscount !== 'ADMIN_SET_COST_DELIVERY' ? getValues().codeDiscount : ''}</div>
        </Col>
      </Row>
    </div>
  );
};

export default ShipMethod;

ShipMethod.propTypes = {
  listProduct: PropTypes.array,
  isLoadingTypeDelivery: PropTypes.bool,
  status: PropTypes.string,
};
