import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import "./product-detail-mobile.scss";
import { get, post } from "../../helpers/api_helper";
import Select from "react-select";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CKEditorComponent from '../../components/CKEditor';

import DropzoneCustomize from "../../components/Dropzone";
import ModalConfirm from "../../components/Modal/modalConfirm";
import {
  Badge,
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Card,
  ModalFooter,
} from "reactstrap";
import { NumberInput } from "../../utils";
import noPic from "../../assets/images/assets/no-images.png";
import picUpload from "../../assets/images/assets/upload.png";
import PicInProduct from "./pic-in-product";
import { WithContext as ReactTags } from "react-tag-input";
import toastr from "toastr";
import copy from 'copy-to-clipboard';
const ProductDetail = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [pagination, setPagination] = useState({
    limit: 30,
    offset: 0,
    total: null,
  });
  const [listProducts, setListProducts] = useState([]);
  const [listCategories, setListCategories] = useState([]);
  const [tagPopup, setTagPopup] = useState(false);
  const [picturePopup, setPicturePopup] = useState(false);
  const [pictureUploadPopup, setPictureUploadPopup] = useState(false);
  const [error, setError] = useState(false);
  const [pictureAttributeUploadPopup, setPictureAttributeUploadPopup] =
    useState(null);
  const [cancelModal, setCancelModal] = useState(false);
  const [promotionAttribute, setPromotionAttribute] = useState(false);

  const [product, setProduct] = useState({
    id: '',
    categoryId: "",
    ProductTags: [],
    ProductAttributes: [
      {
        isNew: true,
        id: "0",
      },
    ],
    name: "",
    weight: "",
    length: "",
    width: "",
    height: "",
    detail: "",
    picture: [],
    soldOut: false,
    discounts: [],
  });

  const formatListProduct = (data, addMore = false) => {
    const { list, total, limit, offset } = data;
    setPagination({
      total,
      limit,
      offset,
    });
    const products = list.map((product) => {
      const { name, picture, id } = product;
      const img = picture.find((p) => p.type === "image");
      return {
        name,
        id,
        picture: img?.url,
      };
    });
    return addMore ? [...listProducts, ...products] : products;
  };

  useEffect(() => {
    get(`/products/list?offset=${pagination.offset}&limit=${pagination.limit}`)
      .then((data) => formatListProduct(data))
      .then((list) => {
        setListProducts(list);
      });
    get(`/categories/list`).then((data) => {
      setListCategories(
        data.map((cate) => ({
          value: cate.id,
          label: cate.name,
        }))
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (slug) {
      get(`/products/${slug}`)
        .then((data) => {
          setProduct({
            ...data,
            categoryId: data.categoryId,
            ProductTags: data.ProductTags,
            ProductAttributes: data.ProductAttributes,
            name: data.name,
            weight: data.weight,
            length: data.length,
            width: data.width,
            height: data.height,
            detail: data.ProductDetail.detail,
            picture: data.picture,
            soldOut: data.soldOut,
            id: data.id,
            productDetailId: data.ProductDetail.id,
            discounts: data.discounts,

          });
        })
        .finally(() => { });
    }
  }, [slug]);

  const handleChangeSoldOut = (soldOut) => {
    setProduct({ ...product, soldOut: !soldOut });
  };

  const handleDelete = (tag) => {
    const temp = { ...product };
    let listTag = temp.ProductTags.filter((item, index) => index !== tag);
    temp.ProductTags = listTag;
    setProduct(temp);
  };

  const handleAddition = (tag) => {
    const temp = { ...product };
    temp.ProductTags.push({
      id: tag,
      tag: tag,
    });
    setProduct(temp);
  };

  const onChangePictureDetail = (pictures) => {
    console.log(pictures);
  };

  const onUploadSuccess = (url, type) => {
    const temp = { ...product };
    const newPicture = {
      url,
      type: (type || "").split("/")?.[0],
    };
    temp.picture.push(newPicture);
    setProduct(temp);
  };

  const onAddmoreProductAttribute = () => {
    const temp = { ...product };
    temp.ProductAttributes.push({
      isNew: true,
      id: temp.length + 1,
      active: true
    });
    setProduct(temp);
  };

  const onRemoveProductAttribute = (index) => {
    const temp = { ...product };
    if (temp?.ProductAttributes?.[index]) {
      if (temp.ProductAttributes[index].isNew) {
        temp.ProductAttributes = temp.ProductAttributes.filter((_, key) => key !== index);
      } else {
        temp.ProductAttributes[index].isWillDelete = true;
      }
    }
    setProduct(temp);
  };

  const onChangeProductAttribute = (index, key, value) => {
    const temp = { ...product };
    temp.ProductAttributes[index][key] = value;
    setProduct(temp);
  };

  const onUploadSuccessProductAttributePicture = (url, type) => {
    const temp = { ...product };
    temp.ProductAttributes[pictureAttributeUploadPopup].picture = url;
    setProduct(temp);
    setPictureAttributeUploadPopup(null);
  };

  const handleCreateProduct = () => {
    let tempIndexError = false;

    const {
      id,
      name,
      height,
      width,
      length,
      weight,
      ProductAttributes,
      categoryId,
      soldOut,
      detail,
      ProductTags,
      picture,
      productDetailId,
      isWillDelete,
      discounts,
    } = product;

    if (
      !name ||
      !categoryId ||
      !height ||
      !width ||
      !length ||
      !weight ||
      !detail ||
      (
        (!Array.isArray(ProductAttributes) || ProductAttributes.filter(e => !e.isWillDelete).length === 0) && !isWillDelete
      )
    ) {
      tempIndexError = true;
      setError(true);
      return false;
    }

    ProductAttributes.every((productAttribute) => {
      const { price, attribute } = productAttribute;
      if (!price || !attribute) {
        setError(true);
        tempIndexError = true;
        return false;
      }
    });

    if (tempIndexError) {
      setError(true);
      toastr.error(
        "Có 1 số sản phẩm chưa điền thông tin. Vui lòng kiểm tra lại"
      );
      return;
    }

    const normalizeData = [
      {
        id,
        name,
        picture,
        height,
        width,
        length,
        weight,
        categoryId,
        ProductDetail: {
          id: productDetailId,
          detail,
        },
        soldOut,
        discounts: discounts?.sort((a, b) => (b.quantity > a.quantity ? -1 : 1)),
        ProductAttributes: ProductAttributes.map(
          ({ id, isNew, isWillDelete, attribute, picture, cost, price, active, externalId }) => ({
            id,
            isNew,
            isWillDelete,
            attribute,
            picture: picture || undefined,
            cost: cost || undefined,
            price,
            active: !!active,
            externalId
          })
        ),
        ProductTags:
          ProductTags?.length > 0
            ? ProductTags?.map(({ tag }) => tag)
            : undefined,
      },
    ];

    post("/products/upsert", {
      products: normalizeData,
    })
      .then(() => {
        toastr.success("Cập nhật thành công");
        navigate('/product-list-mobile');
        setProduct({
          categoryId: "",
          ProductTags: [],
          ProductAttributes: [
            {
              isNew: true,
              id: "0",
            },
          ],
          name: "",
          weight: "",
          length: "",
          width: "",
          height: "",
          detail: "",
          picture: [],
          soldOut: false,
        });
      })
      .catch((err) => {
        toastr.error(err.error);
      });
  };

  const handleDeleteProduct = () => {

    const {
      id,
      name,
      height,
      width,
      length,
      weight,
      ProductAttributes,
      categoryId,
      soldOut,
      detail,
      ProductTags,
      picture,
    } = product;

    const normalizeData = [
      {
        id,
        isWillDelete: true,
        name,
        picture,
        height,
        width,
        length,
        weight,
        categoryId,
        ProductDetail: {
          detail,
        },
        soldOut,
        ProductAttributes: ProductAttributes.map(
          ({ id, isNew, isWillDelete, attribute, picture, cost, price, externalId }) => ({
            id,
            isNew,
            isWillDelete,
            attribute,
            picture: picture || undefined,
            cost: cost || undefined,
            price,
            externalId
          })
        ),
        ProductTags:
          ProductTags?.length > 0
            ? ProductTags?.map(({ tag }) => tag)
            : undefined,
      },
    ];

    post("/products/upsert", {
      products: normalizeData,
    })
      .then(() => {
        toastr.success("Xóa sản phẩm thành công");
        navigate('/product-list-mobile');
        setProduct({
          categoryId: "",
          ProductTags: [],
          ProductAttributes: [
            {
              isNew: true,
              id: "0",
            },
          ],
          name: "",
          weight: "",
          length: "",
          width: "",
          height: "",
          detail: "",
          picture: [],
          soldOut: false,
        });
      })
      .catch((err) => {
        toastr.error(err.error);
      });
  };
  const onAddmoreDiscount = () => {
    const temp = { ...product }
    if (!temp.discounts) {
      temp.discounts = [];
    }
    const quantityMax = Math.max(...temp.discounts?.map(o => o.quantity), 0)

    temp.discounts.push({
      quantity: quantityMax + 1,
      value: 1,
    });
    setProduct(temp);
  };

  const onChangeDiscounts = (index, key, value) => {
    const temp = { ...product }
    if (temp?.discounts?.[index]) {
      temp.discounts[index][key] = value;
    }
    setProduct(temp);
  };

  const onRemoveDiscounts = (index) => {
    const temp = { ...product }

    if (temp?.discounts?.[index]) {
      temp.discounts.splice(index, 1);
    }
    setProduct(temp);
  };
  return (
    <div className="p-productDetail">
      <Breadcrumbs title="Sản phẩm" breadcrumbItem="Chi tiết Sản Phẩm" />
      <Row style={{ justifyContent: "space-between" }}>
        <Col xs={12}>
          <Badge pill className="rounded-pill badge-soft-info">
            Mã sản phẩm: {product?.id}
          </Badge>
        </Col>
        <Col xs={12} className="mt-2">
          <div style={{ display: "flex", alignItems: "center" }}>
            <label style={{ cursor: "pointer", margin: 0 }}>
              Hết hàng
            </label>
            <input
              checked={product?.soldOut}
              onChange={() => handleChangeSoldOut(product?.soldOut)}
              id="soldOut"
              type="checkbox"
              style={{ cursor: "pointer", marginLeft: 5 }}
            />
          </div>
        </Col>
      </Row>
      <Select
        value={
          product?.categoryId
            ? listCategories.find((cate) => cate.value === product.categoryId)
            : null
        }
        onChange={(option) =>
          setProduct({ ...product, categoryId: option.value })
        }
        placeholder="Chọn danh mục"
        options={listCategories}
        isClearable
        classNamePrefix="select2-selection"
        className="mt-2"
      />
      <Row className="m-0 mt-2">
        <Col md={12} className="p-0">
          <Label className="mb-1">Tên sản phẩm</Label>
          <Input
            value={product?.name}
            onChange={(e) => setProduct({ ...product, name: e.target.value })}
          />
          <Row className="mt-2">
            <Col md={3} xs={6}>
              <Label className="mb-1">Nặng</Label>
              <NumberInput
                onChange={(e) => setProduct({ ...product, weight: e.value })}
                customInput={Input}
                suffix={" g"}
                value={product?.weight}
                decimalScale={0}
              />
            </Col>
            <Col md={3} xs={6}>
              <Label className="mb-1">Dài</Label>
              <NumberInput
                onChange={(e) => setProduct({ ...product, length: e.value })}
                customInput={Input}
                suffix={" cm"}
                value={product?.length}
                decimalScale={0}
              />
            </Col>
            <Col md={3} xs={6} className="mt-2">
              <Label className="mb-1">Rộng</Label>
              <NumberInput
                onChange={(e) => setProduct({ ...product, width: e.value })}
                customInput={Input}
                suffix={" cm"}
                value={product?.width}
                decimalScale={0}
              />
            </Col>
            <Col md={3} xs={6} className="mt-2">
              <Label className="mb-1">Cao</Label>
              <NumberInput
                onChange={(e) => setProduct({ ...product, height: e.value })}
                customInput={Input}
                suffix={" cm"}
                value={product?.height}
                decimalScale={0}
              />
            </Col>
          </Row>
        </Col>
        <Col md={12} className="mt-3 p-0 border">
          <CKEditorComponent
            onChange={(e, value) =>
              setProduct({ ...product, detail: value.getData() })
            }
            data={product?.detail || ""}
          />
        </Col>
        <Col md={12} className="mt-1 p-0">
          <Button
            className="btn-info p-1"
            style={{
              marginRight: 5,
              width: "100%",
              maxWidth: "80px",
            }}
            onClick={() => setTagPopup(true)}
          >
            Tag
          </Button>
          <Button
            style={{
              marginRight: 5,
              width: "100%",
              maxWidth: "110px",
            }}
            className="btn btn-info p-1"
            onClick={() => setPicturePopup(true)}
          >
            Hiện {product?.picture?.length || 0} ảnh
          </Button>{" "}
          <Button
            className="btn-info p-1"
            style={{
              marginRight: 5,
              width: "100%",
              maxWidth: "110px",
            }}
            onClick={() => {
              setPromotionAttribute(true);
            }}
          >
            Khuyến mãi
          </Button>
        </Col>
        <Col className="mt-2 list-attribute">
          <h5 onClick={onAddmoreProductAttribute}>Thêm mới thuộc tính <i
            className="point-click fa fa-plus text-primary"
          ></i></h5>
          <h5>Đang có {`${product?.ProductAttributes.length || 0} Thuộc tính`}</h5>
          {
            product?.ProductAttributes.map((item, index) => (
              <Card className="p-1">
                <div className="position-relative picture-attribute">
                  {!item.isWillDelete && (
                    <img
                      className="image-upload position-absolute"
                      width={50}
                      height={50}
                      src={picUpload}
                      onClick={() => setPictureAttributeUploadPopup(index)}
                      alt=""
                    />
                  )}
                  <img
                    width={50}
                    height={50}
                    src={item.picture || noPic}
                    alt=""
                  />
                  <div
                    style={{
                      position: 'absolute',
                      right: 10,
                      top: 10
                    }}
                  >
                    <i
                      className={`fa fa-trash text-danger point-click`}
                      onClick={() => onRemoveProductAttribute(index)}
                    ></i>
                  </div>
                </div>

                <div style={{ marginTop: '5px' }}>
                  <Label>Thuộc tính thứ {index + 1}</Label>
                  <Input
                    readOnly={item.isWillDelete}
                    style={item.isWillDelete ? { opacity: 0.2 } : {}}
                    value={item.attribute || ""}
                    onChange={(e) =>
                      onChangeProductAttribute(
                        index,
                        "attribute",
                        e.target.value
                      )
                    }
                    className={`${error && !item.attribute ? "bg-danger" : ""
                      }`}
                  />
                </div>
                <div style={{ marginTop: '5px' }}>
                  <Label>Mã đối soát</Label>
                  <Input
                    readOnly={item.isWillDelete}
                    style={item.isWillDelete ? { opacity: 0.2 } : {}}
                    value={item.externalId || ""}
                    onChange={(e) =>
                      onChangeProductAttribute(
                        index,
                        "externalId",
                        e.target.value
                      )
                    }
                    className={`${error && !item.externalId ? "bg-danger" : ""
                      }`}
                  />
                </div>
                <div style={{ marginTop: '5px' }}>
                  <Label>Giá bán</Label>
                  <NumberInput
                    style={item.isWillDelete ? { opacity: 0.2 } : {}}
                    readOnly={item.isWillDelete}
                    className={`${error !== null && !item.price ? "bg-danger" : ""
                      }`}
                    onChange={(e) =>
                      onChangeProductAttribute(index, "price", e.value)
                    }
                    customInput={Input}
                    value={item.price || 0}
                  />
                </div>
                <div style={{ marginTop: '5px' }}>
                  <Label>Giá gốc</Label>
                  <NumberInput
                    readOnly={item.isWillDelete}
                    style={item.isWillDelete ? { opacity: 0.2 } : {}}
                    onChange={(e) =>
                      onChangeProductAttribute(index, "cost", e.value)
                    }
                    customInput={Input}
                    value={item.cost || 0}
                    suffix={" đ"}
                  />
                </div>
                <div style={{ marginTop: '5px' }}>
                  <Label>Sử dụng</Label>
                  <input
                    onChange={() =>
                      onChangeProductAttribute(index, "active", !item.active)
                    }
                    checked={item?.active}
                    type="checkbox"
                    style={{ cursor: "pointer", marginLeft: 5 }}
                  />
                </div>

              </Card>
            ))
          }

          <h5 className="text-center mt-5 text-danger">
            {error !== null && !product?.ProductAttributes?.filter(e => !e.isWillDelete).length
              ? "Cần ít nhất 1 thuộc tính"
              : ""}
          </h5>
        </Col>
      </Row>
      <Button
        className="btn btn-success waves-effect waves-light"
        style={{ float: "right" }}
        type="button"
        onClick={handleCreateProduct}
      >
        Cập nhật
      </Button>
      <Button
        className="btn btn-warning waves-effect waves-light"
        style={{ float: "right", marginRight: 10 }}
        type="button"
        onClick={() => setCancelModal(true)}
      >
        Xóa
      </Button>
      <Modal
        isOpen={tagPopup}
        centered={true}
        className="modal-list-product"
        toggle={() => setTagPopup(false)}
        size="md"
      >
        <ModalHeader toggle={() => setTagPopup(false)}></ModalHeader>
        <ModalBody style={{ minHeight: 100 }}>
          <Row>
            <ReactTags
              handleDelete={(data) => handleDelete(data)}
              handleAddition={(data) => handleAddition(data.id)}
              placeholder="Tag Sản Phẩm"
              readOnly={false}
              allowUnique={true}
              allowDragDrop={false}
              allowAdditionFromPaste={false}
              allowDeleteFromEmptyInput={false}
              tags={product?.ProductTags?.map((item) => ({
                id: item.tag,
                text: <span onClick={() => {
                  copy(item.tag)
                  toastr.info("Copy tag thành công")
                }}>{item.tag}</span>,
              }))}
            />
          </Row>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={picturePopup}
        toggle={() => setPicturePopup(false)}
        centered={true}
        className="modal-list-product"
        size="md"
      >
        <ModalHeader toggle={() => setPicturePopup(false)}></ModalHeader>
        <ModalBody style={{ minHeight: 150, marginTop: 50 }}>
          <PicInProduct
            onChange={onChangePictureDetail}
            onOpenUpload={() => setPictureUploadPopup(true)}
            pictures={product?.picture || []} />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={pictureUploadPopup}
        toggle={() => setPictureUploadPopup(false)}
        centered={true}
      >
        <ModalHeader toggle={() => setPictureUploadPopup(false)}></ModalHeader>
        <ModalBody style={{ minHeight: 150 }}>
          <DropzoneCustomize onUploadSuccess={onUploadSuccess} />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={pictureAttributeUploadPopup !== null}
        toggle={() => setPictureAttributeUploadPopup(null)}
        centered={true}
      >
        <ModalHeader
          toggle={() => setPictureAttributeUploadPopup(null)}
        ></ModalHeader>
        <ModalBody style={{ minHeight: 150 }}>
          <DropzoneCustomize
            onUploadSuccess={onUploadSuccessProductAttributePicture}
            multiple={false}
          />
        </ModalBody>
      </Modal>
      <Modal
        className="promotion-modal"
        isOpen={!!promotionAttribute}
        toggle={() => setPromotionAttribute(false)}
        centered={true}
        size={'md'}
      >
        <>
          <ModalBody style={{ minHeight: 150 }}>
            <table className="w-100">
              <thead>
                <th><i
                  className="point-click fa fa-plus text-primary"
                  onClick={() =>
                    onAddmoreDiscount()
                  }
                ></i></th>
                <th>Số lượng</th>
                <th>Giá giảm</th>
                <th style={{ textAlign: "center" }}>
                </th>
              </thead>
              <tbody>
                {
                  product?.discounts?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}. </td>
                      <td>
                        <NumberInput
                          value={item?.quantity || 0}
                          onChange={(e) =>
                            onChangeDiscounts(
                              index,
                              "quantity",
                              e.value
                            )
                          }
                          customInput={Input}
                          prefix={">= "}
                          suffix={""}
                        />
                      </td>
                      <td>
                        <NumberInput
                          onChange={(e) =>
                            onChangeDiscounts(
                              index,
                              "value",
                              e.value
                            )
                          }
                          customInput={Input}
                          value={item?.value || 0}
                          suffix={" đ"}
                        />
                      </td>
                      <td>
                        <i
                          className={`fa fa-trash text-danger point-click`}
                          onClick={() =>
                            onRemoveDiscounts(
                              index
                            )
                          }
                        ></i>
                      </td>
                    </tr>
                  ))
                }

              </tbody>

            </table>
          </ModalBody>
          <ModalFooter>
            <Button className="btn-secondary" onClick={() => setPromotionAttribute(false)}>Đóng</Button>
          </ModalFooter>
        </>
      </Modal>
      <ModalConfirm
        isOpen={cancelModal}
        title="Xóa sản phẩm"
        desc="Bạn có chắc chắn muốn xóa sản phẩm này?"
        handleAccept={() => { handleDeleteProduct(); setCancelModal(false); }}
        handleCancel={() => setCancelModal(false)}
      />
    </div>
  );
};

export default ProductDetail;
