import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { RenderAmount, docTienBangChu } from "../../utils";
import moment from 'moment';
import Barcode from 'react-barcode';
import { listTransport } from '../OderManager/ShipMethod';
import './print.scss';
const ComponentToPrintOne = React.forwardRef((props, ref) => {
  const {
    id, province, district, ward, listProduct, name, phone, street, email,
    total, productsAmount, vatAmount, vatPercent, costDeliver, note, noteInvoice,
    createdAt, invoiceType, deposit, typeDeliver
  } = props;

  const totalWeight = useMemo(() => {
    let total = 0;
    listProduct.forEach((item) => {
      total += (item.productWeight || item?.Product?.weight || 0) * (item.quantity || 0)
    });

    return total;
  }, [listProduct])
  return (
    <div ref={ref} style={{ background: 'white', minHeight: '100vh' }}>
      <div style={{ fontSize: 12, color: 'black' }}>
        <p style={{ textAlign: "center", position: "relative" }}>
          <h6 style={{ textAlign: "left", fontWeight: "700" }}>
            VĂN PHÒNG PHẨM TÂN TIẾN
            <br />
            15E Nguyễn Thị Minh Khai, Q1
          </h6>
          <span
            style={{
              fontSize: 12,
              position: "absolute",
              textAlign: "right",
              right: 5,
              top: 0
            }}
          >
            <div className="w-100" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Barcode height={20} value={id} displayValue={false} format="CODE128" width={1} margin={0} />
            </div>
            <div>
              Mã: {id}
            </div>
            {moment(createdAt).format('HH:mm DD-MM-YYYY')}
            <div><RenderAmount price={totalWeight} decimalScale={3} suffix=" g" /></div>
          </span>

        </p>
        <h4 style={{ textAlign: "center", fontWeight: "700" }}>
          PHIẾU GIAO HÀNG
        </h4>
        <p style={{ margin: 0, position: "relative" }}><b>Khách</b>: {name || 'Ẩn Danh'} - {phone || 'Không có SDT'}
        </p>
        <p style={{ margin: 0 }}>
          <b>Địa chỉ giao hàng:</b> {`${street ? `${street}, ` : ''}${ward ? `${ward}, ` : ''}${district ? `${district}, ` : ''}${province ? `${province}, ` : ''}`}
        </p>
        {!!note ? <p style={{ margin: 0, whiteSpace: 'pre-line' }}><b>Ghi chú:</b> {note}</p> : null}
        {(!!invoiceType && noteInvoice) ? <p style={{ margin: 0, whiteSpace: 'pre-line' }}><b>Thông tin hoá đơn:</b> {noteInvoice}</p> : ''}

        <div style={{ marginTop: 10 }}>
          <table className="table-list-product">
            <tbody>
              <tr>
                <td>
                  STT
                </td>
                <td>Tên sản phẩm</td>
                <td>Loại</td>
                <td>SL</td>
                <td>Đơn giá</td>
                <td>Thành Tiền</td>
              </tr>
              {listProduct &&
                listProduct.map((item, idx) => (
                  <tr key={idx}>
                    <td>
                      {idx + 1}
                    </td>
                    <td>
                      {item?.Product?.name}
                    </td>
                    <td>
                      {item?.ProductAttribute?.attribute}
                    </td>
                    <td>
                      {item?.quantity}

                    </td>
                    <td>
                      <RenderAmount price={(item?.price || 0) - (item.discountPerQuantity || 0)} />
                    </td>
                    <td>
                      <RenderAmount
                        price={
                          Number(item?.quantity || 0) * Number((item?.price || 0) - (item.discountPerQuantity || 0))
                        }
                      />
                    </td>
                  </tr>
                ))}
              <tr>
                <td
                  colSpan="6"
                  style={{
                    fontSize: 12,
                  }}
                >
                  <div style={{
                    display: 'flex',
                  }}>
                    <span style={{
                      fontWeight: 'bolder'
                    }}>
                      Tổng tiền sản phẩm
                    </span>
                    <span style={{
                      marginLeft: 'auto'
                    }}>
                      <RenderAmount price={productsAmount || 0} />
                    </span>
                  </div>


                </td>
              </tr>
              {vatAmount ? <tr>
                <td
                  colSpan="6"
                  style={{
                    fontSize: 12,
                  }}
                >
                  <div style={{
                    display: 'flex',
                  }}>
                    <span style={{
                      fontWeight: 'bolder'
                    }}>
                      VAT ({vatPercent}%)
                    </span>
                    <span style={{
                      marginLeft: 'auto'
                    }}>
                      <RenderAmount price={vatAmount} />
                    </span>
                  </div>



                </td>
              </tr> : null}
              <tr>
                <td
                  colSpan="6"
                  style={{
                    fontSize: 12,
                  }}
                >
                  <div style={{
                    display: 'flex',
                  }}>
                    <span style={{
                      fontWeight: 'bolder'
                    }}>
                      Phí vận chuyển - {listTransport.find(e => e.id === typeDeliver)?.titlePrint}
                    </span>
                    <span style={{
                      marginLeft: 'auto'
                    }}>
                      <RenderAmount price={costDeliver} />
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  colSpan="6"
                  style={{
                    fontSize: 12,
                  }}
                >
                  <div style={{
                    display: 'flex',
                  }}>
                    <span style={{
                      fontWeight: 'bolder'
                    }}>
                      Tổng cộng
                    </span>
                    <span style={{
                      marginLeft: 'auto'
                    }}>
                      <RenderAmount price={total} />
                    </span>
                  </div>
                </td>
              </tr>
              {
                deposit > 0 ? <>
                  <tr>
                    <td
                      colSpan="6"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      <div style={{
                        display: 'flex',
                      }}>
                        <span style={{
                          fontWeight: 'bolder'
                        }}>
                          Ứng trước/Giảm giá
                        </span>
                        <span style={{
                          marginLeft: 'auto'
                        }}>
                          <RenderAmount price={deposit} />
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="6"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      <div style={{
                        display: 'flex',
                      }}>
                        <span style={{
                          fontWeight: 'bolder'
                        }}>
                          Tổng cộng
                        </span>
                        <span style={{
                          marginLeft: 'auto'
                        }}>
                          <RenderAmount price={total - deposit} />
                        </span>
                      </div>
                    </td>
                  </tr>
                </> : null
              }
            </tbody>
            <tfoot></tfoot>
          </table>
          <p style={{ marginTop: 10 }}>{`Viết bằng chữ: ${docTienBangChu(total - deposit)}`}</p>

          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <div style={{ width: '100%' }}>
              <p style={{ textAlign: 'center', fontWeight: '700', fontSize: 12, margin: 0 }}>Người nhận hàng</p>
              <p style={{ textAlign: 'center' }}>(Kí, ghi rõ họ tên)</p>
            </div>
            <div style={{ width: '100%' }}>
              <p style={{ textAlign: 'center', margin: 0 }}>Ngày ...... Tháng ...... Năm .......</p>
              <p style={{ textAlign: 'center', fontWeight: '700', fontSize: 12, margin: 0 }}>Nhân viên giao hàng</p>
              <p style={{ textAlign: 'center' }}>(Kí, ghi rõ họ tên)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ComponentToPrintOne;

ComponentToPrintOne.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  street: PropTypes.string,
  phone: PropTypes.string,
  province: PropTypes.string,
  district: PropTypes.string,
  ward: PropTypes.string,
  listProduct: PropTypes.array,
  total: PropTypes.number,
  productsAmount: PropTypes.number,
  vatAmount: PropTypes.number,
  costDeliver: PropTypes.number,
  createdAt: PropTypes.string,

};
