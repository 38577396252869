import React, { useEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Button, Label } from "reactstrap";
import slugify from "slugify";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import ShipInfo from "./ShipInfo";
import ShipMethod from "./ShipMethod";
import DeliveryInfo from "./DeliveryInfo";
import Select from "react-select";
import { get, post, put } from "../../helpers/api_helper";
import { getPublic, postPublic } from "../../helpers/api_helper";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { RenderAmount } from "../../utils";
import toastr, { options } from "toastr";
import { useLocation, useNavigate, useParams } from "react-router";
import Loading from "../../components/Loading/loading";
import "./index.scss";
import ComponentToPrintOne from "../ListDetailOder/printComponentOne";
import ExportExcelComponent from "../ListDetailOder/exportExcelComponent";
import ModalConfirm from "../../components/Modal/modalConfirm";
import LocationViettelProvider from './providerViettelLocation';
import moment from 'moment';


const CANCEL_REASON = [
  {
    "title": "Khách y/c hủy"
  },
  {
    "title": "Đơn ảo"
  },
]
const DEFAULT_ORDER = {
  name: null,
  phone: null,
  email: null,
  province: null,
  district: null,
  ward: null,
  street: '',
  note: null,
  noteInvoice: null,
  typeDelivery: null,
  invoiceType: null,
  costDeliver: null,
  isSendMail: false,
}
const OderManager = () => {
  const refDeliveryInfo = useRef(null)
  const location = useLocation();
  const { slug } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState("CREATE");
  const [statusOder, setStatusOder] = useState("");
  const [idOder, setIdOder] = useState(null);
  const [listProduct, setListProduct] = useState([]);
  const [listProductSelect, setListProductSelect] = useState([{ isNew: true }]);
  const [isLoadingTypeDelivery, setIsLoadingTypeDelivery] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dataPopupChangeStatus, setDataPopupChangeStatus] = useState(null);
  const [toggleListCostDelivery, setToggleListCostDelivery] = useState(false);
  const [listCostDelivery, setListCostDelivery] = useState([]);
  const [rate, setRate] = useState(null);
  const [isCod, setIsCod] = useState(true);
  const [tempData, setTempData] = useState({});
  const [statusSendMail, setStatusSendMail] = useState(null);
  const [orderData, setOrderData] = useState(null)
  const [cancelReasonIndex, setCancelReasonIndex] = useState(0);

  const [loading, setLoading] = useState(false);
  const methods = useForm({
    defaultValues: DEFAULT_ORDER
  });
  const methodViettels = useForm();
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors, isDirty },
    watch,
    getValues,
    reset,
    register,
  } = methods;

  const { control: controlViettelPost } = methodViettels;
  const isInputFullAdress = () => {
    const { province, district, street, ward } = getValues();
    if (province?.value && district?.value && ward?.value && street) {
      return true;
    }
    return false;
  }
  const [isShowShipMethod, setIsShowShipMethod] = useState(isInputFullAdress());


  const watchTypeDelivery = watch("typeDelivery");
  const watchProvince = watch("province")
  const watchDistrict = watch("district")
  const watchWard = watch("ward")
  const watchStreet = watch("street")
  const printRef = useRef(null);
  const formRef = useRef(null);
  const exportRef = useRef(null);

  useEffect(() => {
    setIsShowShipMethod(isInputFullAdress())
  }, [watchDistrict, watchProvince, watchWard, watchStreet])


  const cloneBillDetail = (billDetails) => billDetails.map((item) => ({
    attributeName: item?.ProductAttribute?.attribute,
    cost: item.ProductAttribute.cost,
    attributeId: item.ProductAttribute.id,
    productAttributeId: item.ProductAttribute.id,
    productAttributeExternalId: item.ProductAttribute.externalId,
    productId: item.productId,
    productName: item.Product.name,
    productWeight: item.Product.weight,
    price: item.price,
    productPrice: item.ProductAttribute.price,
    quantity: item.quantity,
    discountPerQuantity: item.discountPerQuantity,
    discount: item.discountPerQuantity > 0,
    discounts: item.Product.discounts,
    saved: true,
  }))
  useEffect(() => {
    if (location.state || slug) {
      setLoading(true);
      setStatus('INFO');
      getPublic(`/bills/${location.state?.idBill || slug}`)
        .then((res) => {
          const oder = res.data;
          setOrderData(oder);
          setIdOder(oder.id);
          setStatusOder(oder.status);
          setRate(oder.rate);
          setIsCod(!!oder.isCod);
          setValue("name", oder.name);
          setValue("phone", oder.phone);
          setValue("email", oder.email);
          setValue("note", oder.note || '');
          setValue("noteInvoice", oder.noteInvoice || '');
          setValue("emailReceivedVAT", oder.emailReceivedVAT || '');
          setValue("codeDiscount", oder.codeDiscount || '');
          setValue("deposit", oder.deposit || 0);
          setValue('costDeliver', oder.costDeliver)
          setValue(
            "province",
            oder.Province && {
              value: oder.Province.id,
              label: oder.Province.name,
            }
          );
          setValue(
            "district",
            oder.District && {
              value: oder.District.id,
              label: oder.District.name,
            }
          );
          setValue(
            "ward",
            oder.Ward && { label: oder.Ward.name, value: oder.Ward.id }
          );

          setValue("street", oder.addressDeliver.street);
          const cloneListSelect = cloneBillDetail(oder.BillDetails)
          setListProductSelect(cloneListSelect);
          setValue("typeDelivery", oder.typeDeliver);
          setValue('invoiceType', oder.invoiceType);
          setValue('vatPercent', `${oder.vatPercent}`);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setDataKhachVangLai();
    }
    return () => {
      reset(DEFAULT_ORDER);
      setIsCod(false);
      setRate(null);
      setIdOder(null);
      setStatusOder(null);
      setStatus("CREATE");
      setListProductSelect([{ isNew: true }]);
    };
  }, [location, slug]);
  const timeoutSearchLastBillByPhone = useRef();

  const handleChangePhone = (phone) => {
    if (idOder) {
      return
    }
    if (timeoutSearchLastBillByPhone.current) {
      clearTimeout(timeoutSearchLastBillByPhone.current);
    }

    if (phone?.length >= 10) {
      timeoutSearchLastBillByPhone.current = setTimeout(() => {
        get(`/bills/get-last-info-by-phone/${phone}`, {}).then(
          (bill) => {
            if (bill) {
              setValue("name", bill.name);
              setValue("phone", bill.phone);
              setValue("email", bill.email);
              setValue("note", bill.note);
              setValue("invoiceType", bill.invoiceType);
              setValue("vatPercent", `${bill.vatPercent}`);
              setValue("noteInvoice", bill.noteInvoice);
              setValue("emailReceivedVAT", bill.emailReceivedVAT);

              // setValue("typeDelivery", bill.typeDeliver);
              // setValue("costDeliver", bill.costDeliver);
              // setValue("deposit", bill.deposit);
              // setValue("codeDiscount", bill.codeDiscount);
              if (bill.BillDetails) {
                const cloneListSelect = cloneBillDetail(bill.BillDetails)
                setListProductSelect(cloneListSelect);
              }
              if (bill.addressDeliver) {
                setValue('street', bill.addressDeliver.street);
              }
              if (bill.Province) {
                setValue("province", {
                  label: bill.Province.name,
                  value: bill.province,
                });
              }
              if (bill.District) {
                setTimeout(() => {
                  setValue("district", {
                    label: bill.District.name,
                    value: bill.district,
                  });
                }, 1000);
              }
              if (bill.Ward) {
                setTimeout(() => {
                  setValue("ward", {
                    label: bill.Ward.name,
                    value: bill.ward,
                  });
                }, 1000);
              }
            }
          }
        )
          .catch(() => { })
      }, 300)
    }
  };

  useEffect(() => {
    get(`/products/list?offset=0&limit=10000`).then((data) => {
      setListProduct(data.list);
    });
  }, []);

  useEffect(() => {
    if (!isDirty) {
      return;
    }
    if (!(location.state || slug) || (idOder && getValues().typeDelivery)) {
      setValue("typeDelivery", null);
      setValue("costDeliver", null);

    }
  }, [listProductSelect, isDirty])

  useEffect(() => {
    if (!isDirty) {
      return;
    }

    const { province, district, ward, street, typeDelivery } = getValues();

    if (status === 'INFO' || !isInputFullAdress()) {
      return;
    }
    if (typeDelivery === 'IN_SHOP') {
      setValue('costDeliver', 0)
      return;
    }

    if (typeDelivery === 'SHOP_SUGGESTION') {
      return;
    }

    if (isInputFullAdress() && typeDelivery) {
      setIsLoadingTypeDelivery(true);
      postPublic(`/bills/estimate-delivery-fee`, {
        address: {
          province: province ? province.value : "",
          district: district ? district.value : "",
          ward: ward ? ward.value : "",
          street,
        },
        typeDeliver: typeDelivery,
        weight: totalWeight(),
        price: refDeliveryInfo?.current?.totalProduct(),
      })
        .then(({ data: { cost } }) => {
          setValue('costDeliver', cost)
        })
        .catch((err) => {
          setValue('costDeliver', null)
          setValue("typeDelivery", null);
          toastr.error(err.response.data.error);
        })
        .finally(() => {
          setIsLoadingTypeDelivery(false);
        });
    }

  }, [watchTypeDelivery, isDirty]);

  useEffect(() => {
    if (!isOpen || dataPopupChangeStatus?.type !== 'cancel') {
      return;
    }

    setDataPopupChangeStatus({
      ...dataPopupChangeStatus,
      subTitle: renderDesCancelModal(),
    })
  }, [cancelReasonIndex, isOpen, dataPopupChangeStatus]);

  const formatDataProduct = (data) =>
    data.map((item) => ({
      label: item.name,
      value: item.id,
      data: item
    }));

  const formatDataAttribute = (data) =>
    data?.map((item) => ({
      label: item.attribute,
      value: item.id,
      data: item,
    }));

  const addMoreRow = () => {
    const clone = [...listProductSelect];
    clone.unshift({
      isNew: true,
    });
    setListProductSelect(clone);
  };

  const deleteRow = (idx) => {
    const clone = [...listProductSelect];
    clone.splice(idx, 1);
    setListProductSelect(clone);
  };

  const onChangeListProduct = (index, option) => {
    if (option) {
      let cloneListSelect = [...listProductSelect];
      get(`/products/${option.value}`).then((data) => {
        cloneListSelect[index]["listAttributes"] = data.ProductAttributes;
        cloneListSelect[index]["productId"] = data.id;
        cloneListSelect[index]["productName"] = data.name;
        cloneListSelect[index]["attributeId"] = "";
        cloneListSelect[index]["attributeName"] = "";
        cloneListSelect[index]["productWeight"] = data.weight
        cloneListSelect[index]["discounts"] = data.discounts;
        cloneListSelect[index]["discountPerQuantity"] = 0;
        setListProductSelect(cloneListSelect);
      });
    }
  };

  const onChangeListAttribute = (index, option) => {
    if (option) {
      let cloneListSelect = [...listProductSelect];
      let attribute = cloneListSelect[index].listAttributes.find(
        (item) => item.id === option.value
      );
      cloneListSelect[index]["productAttributeId"] = attribute.id;
      cloneListSelect[index]["attributeId"] = attribute.id;
      cloneListSelect[index]["attributeName"] = attribute.attribute;
      cloneListSelect[index]["price"] = attribute.price;
      cloneListSelect[index]["cost"] = attribute.cost || 0;
      cloneListSelect[index]["discountPerQuantity"] = 0;
      cloneListSelect[index]["discount"] = false;
      if (cloneListSelect[index]["quantity"] > 0 && cloneListSelect[index]?.discounts) {
        let tempDiscount = cloneListSelect[index]?.discounts.sort((a, b) => b.quantity - a.quantity) || [];
        for (let i = 0; i < tempDiscount.length; i++) {
          if (Number(cloneListSelect[index]["quantity"]) >= tempDiscount[i].quantity) {
            cloneListSelect[index]["discountPerQuantity"] = Number(tempDiscount[i].value);
            cloneListSelect[index]["discount"] = true;
            break;
          }
        }
      } else {
        cloneListSelect[index]["discountPerQuantity"] = 0;
      }
      setListProductSelect(cloneListSelect);
    }
  };

  const onChangeQuantity = (index, value) => {
    let cloneListSelect = [...listProductSelect];
    cloneListSelect[index]["discountPerQuantity"] = 0;
    cloneListSelect[index]["discount"] = false;
    if (cloneListSelect[index]?.discounts) {
      let tempDiscount = cloneListSelect[index]?.discounts.sort((a, b) => b.quantity - a.quantity) || [];

      for (let i = 0; i < tempDiscount.length; i++) {
        if (Number(value) >= tempDiscount[i].quantity) {
          cloneListSelect[index]["discountPerQuantity"] = Number(tempDiscount[i].value);
          cloneListSelect[index]["discount"] = true;
          break;
        }
      }
    }

    cloneListSelect[index]["quantity"] = Number(value);
    setListProductSelect(cloneListSelect);
  };
  const setDataKhachVangLai = () => {
    setValue("name", 'Khách');
    setValue("phone", '');
    setValue("email", 'thongtinmacdinh@gmail.com');
    setValue('street', '15E Nguyễn Thị Minh Khai');
    setValue("province", {
      label: 'Thành phố Hồ Chí Minh',
      value: 79,
    });
    setValue("district", {
      label: 'Quận 1',
      value: 760,
    });

    setValue("ward", {
      label: 'Phường Bến Nghé',
      value: 26740,
    });
  }


  const sendBillMail = () => {
    setStatusSendMail('SENDING');
    post(`/bills/send-mail/${slug}`)
      .then(() => {
        toastr.success('Gửi mail thành công');
        setStatusSendMail(null);
      })
      .catch(() => {
        toastr.error('Gửi mail Không thành công. Vui lòng thử lại');
        setStatusSendMail('OPEN');
      })
  }

  const getListCostDelivery = (isCod) => {
    setToggleListCostDelivery(true)
    setListCostDelivery([])
    post(`/bills/get-list-cost-delivery/${idOder}`, {
      isCod
    }).then((res) => {
      console.log(res)
      setListCostDelivery(res)
    }).catch((err) => {
      toastr.error(err?.error)
    });
  }

  const submitFrom = (data, cb) => {
    let validate = false;
    if (status === "CREATE") {
      let message = '';
      if (listProductSelect.length === 0) {
        message = "Vui lòng chọn sản phẩm";
        validate = true
      }
      listProductSelect.map((item) => {
        if (item.quantity <= 0 || !item.quantity) {
          message = "Số lượng sản phẩm phải lớn hơn 0";
          validate = true
        }
        if (!item.attributeId) {
          message = "Vui lòng chọn thuộc tính cho sản phẩm";
          validate = true
        }
      })

      if (validate) {
        toastr.error(message)
        return;
      }

      setLoading(true);

      post("/bills/create", {
        address: {
          province: data.province.value,
          ward: data.ward.value,
          district: data.district.value,
          street: data.street,
        },
        typeDeliver: data.typeDelivery,
        costDeliver: data.costDeliver,
        noteInvoice: data.noteInvoice || undefined,
        isSendMail: !!data.isSendMail,
        emailReceivedVAT: data.emailReceivedVAT || undefined,
        invoiceType: data.invoiceType,
        vatPercent: data.vatPercent ? Number(data.vatPercent) : null,
        deposit: data.deposit || 0,
        note: data.note || undefined,
        isCod: isCod,
        rate: rate || undefined,
        name: data.name,
        phone: data.phone,
        email: data.email,
        billDetails:
          listProductSelect &&
          listProductSelect.map((item) => ({
            productId: item.productId,
            productAttributeId: item.productAttributeId,
            quantity: Number(item.quantity),
            discount: !!item.discount,
          })),
      })
        .then((data) => {
          navigate(`/oder-manager/${data.billId}`)
          setIdOder(data.billId);
          toastr.success("Thêm đơn hàng thành công");
        })
        .catch((err) => {
          toastr.error(
            err?.error || "Xảy ra lỗi trong quá trình tạo đơn hàng vui lòng thử lại"
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
    if (status === "UPDATE") {
      let message = ''
      if (listProductSelect.length === 0) {
        message = "Vui lòng chọn sản phẩm";
        validate = true
      }
      listProductSelect.map((item) => {
        if (item.quantity <= 0 || !item.quantity) {
          message = "Số lượng sản phẩm phải lớn hơn 0";
          validate = true
        }

        if (!item.attributeId) {
          message = "Vui lòng chọn thuộc tính cho sản phẩm";
          validate = true
        }
      })

      if (validate) {
        toastr.error(message)
        return;
      }
      setLoading(true);
      put(`/bills/update/${idOder}`, {
        address: {
          province: data.province.value,
          ward: data.ward.value,
          district: data.district.value,
          street: data.street,
        },
        isCod: isCod,
        rate: rate || undefined,
        typeDeliver: data.typeDelivery,
        costDeliver: data.costDeliver,
        noteInvoice: data.noteInvoice || "",
        emailReceivedVAT: data.emailReceivedVAT || undefined,
        isSendMail: !!data.isSendMail,
        invoiceType: data.invoiceType,
        vatPercent: data.vatPercent ? Number(data.vatPercent) : null,
        deposit: data.deposit || 0,
        note: data.note || "",
        name: data.name,
        phone: data.phone,
        email: data.email,
        billDetails:
          listProductSelect &&
          listProductSelect.map((item) => ({
            saved: !!item.saved,
            productId: item.productId,
            productAttributeId: item.productAttributeId,
            quantity: Number(item.quantity),
            discount: !!item.discount,
          })),
      })
        .then((data) => {
          setStatus("INFO");
          setToggleListCostDelivery(null)
          toastr.success("Cập nhật đơn hàng thành công");
          if (typeof cb === 'function') {
            cb();
          } else {
            navigate(0);
          }

        })
        .catch((err) => {
          console.log(err)
          toastr.error(
            "Xảy ra lỗi trong quá trình cập nhật đơn hàng vui lòng thử lại"
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleCancel = () => {
    post("/bills/status/cancel", { ids: [idOder], cancelReason: CANCEL_REASON[cancelReasonIndex].title })
      .then((res) => {
        navigate("/status-oder");
        toastr.success("Hủy đơn thành công");
      })
      .catch((err) => {
        toastr.error(err.error);
      });
  };

  const handleConfirmed = (cb) => {
    setIsOpen(false);
    setLoading(true);
    post("/bills/status/confirm", { ids: [idOder] })
      .then((res) => {
        if (res.result) {
          toastr.success("Cập nhật thành công");
          cb();
          if (cb) {
            cb()
          } else {
            navigate('/status-oder')
            toastr.success("Cập nhật thành công");
          }
        }
      })
      .catch((err) => {
        toastr.error(err.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePreparing = () => {
    setIsOpen(false);
    setLoading(true);

    post("/bills/status/prepare", { ids: [idOder] })
      .then((res) => {
        if (res.result) {
          navigate(0)
          toastr.success("Cập nhật thành công");
        }
      })
      .catch((err) => {
        toastr.error(err.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCheckPackage = () => {
    setIsOpen(false);
    setLoading(true);

    post("/bills/status/check-package", { ids: [idOder] })
      .then((res) => {
        if (res.result) {
          navigate(0)
          toastr.success("Cập nhật thành công");
        }
      })
      .catch((err) => {
        toastr.error(err.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePackageIn = () => {
    setIsOpen(false);
    setLoading(true);

    post("/bills/status/in-package", { ids: [idOder] })
      .then((res) => {
        if (res.result) {
          navigate(0)
          toastr.success("Cập nhật thành công");
        }
      })
      .catch((err) => {
        toastr.error(err.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleWaitingPay = () => {
    setIsOpen(false);
    setLoading(true);

    post("/bills/status/waiting-pay", { ids: [idOder] })
      .then((res) => {
        if (res.result) {
          navigate(0)
          toastr.success("Cập nhật thành công");
        }
      })
      .catch((err) => {
        toastr.error(err.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleWaitingExportVAT = () => {
    setIsOpen(false);
    setLoading(true);

    post("/bills/status/waiting-export-vat", { ids: [idOder] })
      .then((res) => {
        if (res.result) {
          navigate(0)
          toastr.success("Cập nhật thành công");
        }
      })
      .catch((err) => {
        toastr.error(err.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeliver = () => {
    setIsOpen(false);
    setLoading(true);
    post("/bills/status/deliver", { ids: [idOder] })
      .then((res) => {
        if (res.result) {
          navigate(0)
          toastr.success("Cập nhật thành công");
        }
      })
      .catch((err) => {
        toastr.error(err.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFinish = () => {
    setIsOpen(false);
    setLoading(true);
    post("/bills/status/finish", { ids: [idOder] })
      .then((res) => {
        if (res.result) {
          navigate(0)
          toastr.success("Cập nhật thành công");
        }
      })
      .catch((err) => {
        toastr.error(err.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const formatDataPrint = (data) => data.map((item) => ({
    ...item,
    Product: {
      name: item.productName
    },
    ProductAttribute: {
      attribute: item.attributeName,
      externalId: item.productAttributeExternalId,
    },
    price: item.price,
    quantity: item.quantity,
    discountPerQuantity: item.discountPerQuantity
  }))

  const saveCostDelivery = async () => {
    handleSubmit(submitFrom)(handleDeliver);
  }

  const mappingViettelPost = async () => {
    const { ward } = methodViettels.getValues();
    if (!ward?.value) {
      toastr.error('Vui lòng chọn phường xã Viettel Post')
      return;
    }
    post('/addresses/viettel-post-update-mapping', {
      wardIdOriginal: orderData.addressDeliver.ward,
      wardId: ward.value
    }).then(() => {
      toastr.success('Lưu thành công. Đang tải lại danh sách phí');
      getListCostDelivery(isCod);
    })
      .catch(() => {
        toastr.error('Có lỗi xảy ra khi lưu, Vui lòng liên hệ admin')
      })
  }

  const customFilter = (option, searchText) => {
    if (searchText === '') {
      return true;
    }
    const slugLabel = slugify((option.label || '').toLowerCase());
    let isIncludes = true;
    let isIncludeTags = false;
    searchText.split(' ').forEach((e) => {
      const temp = slugify(e).toLowerCase();
      isIncludes = slugLabel.includes(temp) && isIncludes;

    });
    if (option.data.data.ProductTags?.length > 0) {
      option.data.data.ProductTags.forEach(({ tag }) => {
        let tempCondition = true;
        const tagNormalize = slugify(tag).toLowerCase();
        searchText.split(' ').forEach((e) => {
          const temp = slugify(e).toLowerCase();
          tempCondition = tagNormalize.includes(temp) && tempCondition;
        });
        isIncludeTags = tempCondition || isIncludeTags;
      })
    }
    return isIncludes || isIncludeTags;
  }

  const totalWeight = () => {
    let total = 0;

    listProductSelect.forEach((item) => {
      total += (item.productWeight || 0) * (item.quantity || 0)
    });

    return total;
  }

  const renderDesCancelModal = () => {
    return <>
      <div>Bạn có chắc chắn muốn hủy đơn hàng này?</div>
      <div >
        Lý do huỷ
      </div>
      {CANCEL_REASON.map((e, i) => <div className="text-start">
        <Label>
          <input type="radio" checked={cancelReasonIndex === i} name="cancelReason" onChange={() => {
            setCancelReasonIndex(i);
          }} />
          {" "}
          {e.title}
        </Label>
      </div>)}
    </>
  }

  return (
    <div className="oder-manager">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitFrom)} ref={formRef}>
          <Breadcrumbs title="Đơn hàng" breadcrumbItem="Đơn hàng" />
          {loading ? (
            <Loading
              height={50}
              width={50}
              className="loading-custom-page"
              type="spinningBubbles"
            />
          ) : (
            <div className="oder-manager_content">
              {(status === "UPDATE") | (status === "INFO") && idOder ? (
                <p className="mt-3">{`Mã đơn: ${idOder}`} - {moment(orderData.createdAt).format('HH:mm DD-MM-YYYY')}</p>
              ) : (
                ""
              )}
              <div className="table-product">
                <table>
                  <thead>
                    <tr>
                      <th>Tên sản phẩm</th>
                      <th>Thuộc tính</th>
                      <th>Số lượng</th>
                      <th className="text-right">Đơn giá</th>
                      <th className="text-right">Tổng tiền</th>
                      {status !== "INFO" && (
                        <th>
                          <i
                            onClick={addMoreRow}
                            className="point-click fa fa-plus text-primary"
                          />
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {listProductSelect &&
                      listProductSelect.map((item, idx) => (
                        <tr key={`table-product-${idx}`}>
                          <td>
                            <Select
                              styles={{
                                option: (styles, { data }) => {
                                  return {
                                    ...styles,
                                  };
                                },
                              }}
                              value={
                                item.productId
                                  ? {
                                    value: item.productId,
                                    label: item.productName,
                                    data: item,
                                  }
                                  : {
                                    value: "Chọn sản phẩm",
                                    label: "Chọn sản phẩm",
                                  }
                              }
                              onChange={
                                (option) => {
                                  onChangeListProduct(idx, option)
                                }

                              }
                              placeholder="Chọn sản phẩm"
                              noOptionsMessage={() => "Không tìm thấy"}
                              filterOption={customFilter}
                              options={formatDataProduct(listProduct)}
                              isDisabled={status === "INFO" || item.saved}
                              classNamePrefix="select2-selection"
                            />
                            {(!!item.productPrice && item.productPrice !== item.price) ?
                              <>
                                <span className="text-danger">Sản phẩm này bị đổi giá từ <RenderAmount
                                  price={item.price}
                                /> thành <RenderAmount
                                    price={item.productPrice}
                                  /></span>
                              </>
                              : ''}

                          </td>
                          <td>
                            <Select
                              styles={{
                                option: (styles, { data }) => {
                                  return {
                                    ...styles,
                                    color: data?.data?.active ? 'black' : 'red',
                                  };
                                },
                              }}
                              value={
                                item.attributeId
                                  ? {
                                    value: item.attributeId,
                                    label: item.attributeName,
                                  }
                                  : {
                                    label: "Chọn thuộc tính",
                                  }
                              }
                              onChange={(option) => {
                                if (!option?.data?.active) {
                                  toastr.error('Thuộc tính này đang không hoạt động, không thể chọn')
                                  return;
                                }
                                onChangeListAttribute(idx, option)
                              }}
                              placeholder="Chọn thuộc tính"
                              noOptionsMessage={() => "Không tìm thấy"}
                              options={formatDataAttribute(item.listAttributes)}
                              isDisabled={status === "INFO" || item.saved}
                              classNamePrefix="select2-selection"
                            />
                          </td>
                          <td>
                            <input
                              value={item.quantity | ""}
                              onChange={(e) => {
                                onChangeQuantity(idx, e.target.value);
                              }}
                              onKeyDown={(event) => {
                                if (event.code === "Enter" || event.code === "NumpadEnter") {
                                  event.preventDefault()
                                  addMoreRow()
                                }
                              }}
                              className="table-product_input"
                              placeholder="Số lượng"
                              disabled={status === "INFO"}
                            />
                          </td>
                          <td className="text-right">
                            {
                              <RenderAmount
                                price={Number(item.price) - Number(item.discountPerQuantity || 0)}
                              />
                            }
                          </td>
                          <td className="text-right">
                            {
                              <RenderAmount
                                price={
                                  item.quantity && item.price
                                    ? Number(item.quantity) * (Number(item.price) - Number(item.discountPerQuantity || 0))
                                    : 0
                                }
                              />
                            }
                          </td>
                          {status !== "INFO" && (
                            <td onClick={() => deleteRow(idx)}>
                              <i className="fa fa-trash text-danger point-click" />
                            </td>
                          )}
                        </tr>
                      ))}

                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={5} className="text-right"><h4>Trọng lượng: <RenderAmount price={totalWeight()} decimalScale={3} suffix=" g" /></h4></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <ShipInfo status={status} idOder={idOder} handleChangePhone={handleChangePhone} />
              {isShowShipMethod ? <ShipMethod
                listProduct={listProductSelect}
                status={status}
                isLoadingTypeDelivery={isLoadingTypeDelivery}
              /> : ''}

              {rate ?
                <div className="delivery-info">
                  <h5>Thu COD: {!!isCod ? 'Có' : 'Không'}</h5>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <td width={'30%'}>
                          Tên đơn vị
                        </td>
                        <td width={'20%'}>
                          Loại
                        </td>
                        <td width={'30%'}>
                          Thời gian
                        </td>
                        <td width={'20%'}>
                          Giá
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td >
                          <label style={{ display: 'flex', alignItems: 'center' }}>
                            <div>
                              {rate?.carrierLogo ? <>
                                <img height={40} src={rate?.carrierLogo} alt="logo" />
                                <br />
                              </> : ''}
                              {rate?.carrierName}
                            </div>
                          </label>
                        </td>
                        <td>
                          {rate?.service}
                        </td>
                        <td>
                          {rate?.expected}
                        </td>
                        <td>
                          {RenderAmount({ price: rate?.totalFee })}
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>
                : ''}

              <DeliveryInfo
                ref={refDeliveryInfo}
                loading={loading}
                status={status}
                cancelReason={orderData?.cancelReason}
                listProduct={listProductSelect}
                statusOder={statusOder}
                statusAllowUpdate={orderData?.statusAllowUpdate}
                handleUpdateStatus={() => setStatus("UPDATE")}
                handleCancel={() => {
                  setIsOpen(true);
                  setDataPopupChangeStatus({
                    type: 'cancel',
                    title: 'Hủy đơn hàng',
                    subTitle: renderDesCancelModal(),
                    onSuccess: handleCancel
                  })
                }}
                getListCostDelivery={() => {
                  getListCostDelivery(isCod);
                  setStatus('UPDATE')
                  setTempData({
                    isCod,
                    rate,
                  })
                }}

                handleConfirmed={(onConfirm) => {
                  setIsOpen(true);
                  setDataPopupChangeStatus({
                    title: 'Xác nhận đơn hàng',
                    subTitle: 'Bạn có chắc chắn muốn xác nhận đơn hàng này?',
                    onSuccess: () => {
                      handleConfirmed(onConfirm);
                    }
                  })
                }}
                handlePreparing={() => {
                  setIsOpen(true);
                  setDataPopupChangeStatus({
                    title: 'Chuẩn bị đơn hàng',
                    subTitle: 'Bạn có muốn chuẩn bị đơn hàng này?',
                    onSuccess: handlePreparing
                  })
                }}

                handleCheckPackage={() => {
                  setIsOpen(true);
                  setDataPopupChangeStatus({
                    title: 'kiểm hàng',
                    subTitle: 'Bạn có muốn kiểm đơn hàng này?',
                    onSuccess: handleCheckPackage
                  })
                }}

                handlePackageIn={() => {
                  setIsOpen(true);
                  setDataPopupChangeStatus({
                    title: 'Đóng hàng',
                    subTitle: 'Bạn có muốn đóng gói đơn hàng này?',
                    onSuccess: handlePackageIn
                  })
                }}

                handleDeliver={() => {
                  setIsOpen(true);
                  setDataPopupChangeStatus({
                    title: 'Giao vận chuyển hoặc đợi khách đến lấy',
                    subTitle: 'Bạn có chắc chắn muốn chuyển đơn vị vận chuyển hoặc đợi khách đến lấy?',
                    onSuccess: handleDeliver
                  })
                }}

                handleWaitingPay={() => {
                  setIsOpen(true);
                  setDataPopupChangeStatus({
                    title: 'Đơi thanh toán',
                    subTitle: 'Bạn có muốn đợi thanh toán đơn hàng này?',
                    onSuccess: handleWaitingPay
                  })
                }}

                handleWaitingExportVAT={() => {
                  setIsOpen(true);
                  setDataPopupChangeStatus({
                    title: 'Đơi xuất hoá đơn đỏ',
                    subTitle: 'Bạn có muốn đợi xuất hoá đơn đỏ đơn hàng này?',
                    onSuccess: handleWaitingExportVAT
                  })
                }}

                handleFinish={() => {
                  setIsOpen(true);
                  setDataPopupChangeStatus({
                    title: 'Hoàn thành đơn hàng',
                    subTitle: 'Hoàn thành đơn hàng',
                    onSuccess: handleFinish
                  })
                }}
                sendBillMail={() => {
                  setStatusSendMail('OPEN')
                }}
                printRef={printRef}
                exportRef={exportRef}
                isShowShipMethod={isShowShipMethod}
                billId={location.state?.idBill || slug}
              />
            </div>
          )}
        </form>
      </FormProvider>
      <div style={{ display: "none" }}>
        <ComponentToPrintOne
          ref={printRef}
          name={getValues('name')}
          phone={getValues('phone')}
          email={getValues('email')}
          id={idOder}
          province={getValues('province')?.label}
          district={getValues('district')?.label}
          ward={getValues('ward')?.label}
          street={getValues('street')}
          listProduct={formatDataPrint(listProductSelect)}
          total={orderData?.total}
          productsAmount={orderData?.productsAmount}
          vatAmount={orderData?.vatAmount}
          costDeliver={orderData?.costDeliver}
          createdAt={orderData?.createdAt}
          note={orderData?.note}
          noteInvoice={orderData?.noteInvoice}
          invoiceType={orderData?.invoiceType}
          vatPercent={orderData?.vatPercent}
          deposit={orderData?.deposit}
          typeDeliver={orderData?.typeDeliver}
        />
        <ExportExcelComponent
          ref={exportRef}
          name={getValues('name')}
          phone={getValues('phone')}
          email={getValues('email')}
          id={idOder}
          province={getValues('province')?.label}
          district={getValues('district')?.label}
          ward={getValues('ward')?.label}
          street={getValues('street')}
          listProduct={formatDataPrint(listProductSelect)}
          total={orderData?.total}
          productsAmount={orderData?.productsAmount}
          vatAmount={orderData?.vatAmount}
          vatPercent={orderData?.vatPercent}
          costDeliver={orderData?.costDeliver}
          deposit={orderData?.deposit}
          createdAt={orderData?.createdAt}
          note={orderData?.note}
          noteInvoice={orderData?.noteInvoice}
        />
      </div>
      <ModalConfirm
        isOpen={isOpen}
        title={dataPopupChangeStatus?.title}
        desc={dataPopupChangeStatus?.subTitle}
        handleCancel={() => setIsOpen(false)}
        handleAccept={dataPopupChangeStatus?.onSuccess}
      />
      <ModalConfirm
        isOpen={!!statusSendMail}
        title={'Ban có chắc sẽ gửi hoá đơn qua email không'}
        desc={''}
        handleCancel={() => setStatusSendMail(null)}
        handleAccept={sendBillMail}
        isLoading={statusSendMail === 'SENDING'}
      />
      <Modal isOpen={toggleListCostDelivery} centered={true} size="lg">
        <ModalHeader className="list-cost-delivery-header">
          {listCostDelivery.length > 0 ? <h5 >Chi phí vận chuyển</h5> : ''}

        </ModalHeader>
        <ModalBody align="center">
          {listCostDelivery.length === 0 ? <h5>Đang lấy dữ liệu, vui lòng chờ</h5> : ''}
          {listCostDelivery.filter(e => e.id === 'VIETTELPOST-WRONG_ADDRESS').length > 0 ? <div>
            <h5 className="text-danger">
              Địa chỉ "{orderData?.Ward?.name}, {orderData?.District?.name}, {orderData?.Province?.name}" này được map với địa chỉ ở viettel post. Xin nhập thông tin cho Viettel Post
            </h5>
            <div>
              <FormProvider {...methodViettels}>
                <LocationViettelProvider>
                  {({ provinceOptions, districtOptions, wardOptions }) => (
                    <>
                      <Row className="mb-3 justify-content-between">
                        <label className="col-md-4 col-form-label">
                          Thành phố / Tỉnh
                        </label>
                        <div className="col-md-7">
                          <Controller
                            name="province"
                            control={controlViettelPost}
                            rules={{
                              required: "Thành phố / Tỉnh không được để trống",
                            }}
                            render={({ field: { onChange, ...field } }) => (
                              <>
                                <Select
                                  {...field}
                                  onChange={(value) => {
                                    onChange(value);
                                    setValue('ward', null)
                                  }}
                                  placeholder="Thành phố / Tỉnh"
                                  options={provinceOptions}
                                  isDisabled={status === "INFO"}
                                  classNamePrefix="select2-selection"
                                />
                                {errors.province && (
                                  <p className="p-errors">
                                    {errors.province.message}
                                  </p>
                                )}
                              </>
                            )}
                          />
                        </div>
                      </Row>
                      <Row className="mb-3 justify-content-between">
                        <label className="col-md-4 col-form-label">
                          Quận / Huyện
                        </label>
                        <div className="col-md-7">
                          <Controller
                            name="district"
                            control={controlViettelPost}
                            rules={{ required: "Quận / Huyện không được để trống" }}
                            render={({ field: { onChange, ...field } }) => (
                              <>
                                <Select
                                  {...field}
                                  onChange={(value) => {
                                    onChange(value);
                                    setValue('ward', null)
                                  }}
                                  placeholder="Quận / Huyện"
                                  options={districtOptions}
                                  isDisabled={status === "INFO"}
                                  classNamePrefix="select2-selection"
                                />
                                {errors.district && (
                                  <p className="p-errors">
                                    {errors.district.message}
                                  </p>
                                )}
                              </>
                            )}
                          />
                        </div>
                      </Row>
                      <Row className="mb-3 justify-content-between">
                        <label className="col-md-4 col-form-label">Phường / Xã</label>
                        <div className="col-md-7">
                          <Controller
                            name="ward"
                            control={controlViettelPost}
                            rules={{ required: "Phường / Xã không được để trống" }}
                            render={({ field }) => (
                              <>
                                <Select
                                  {...field}
                                  placeholder="Phường / Xã"
                                  options={wardOptions}
                                  isDisabled={status === "INFO"}
                                  classNamePrefix="select2-selection"
                                />
                                {errors.ward && (
                                  <p className="p-errors">{errors.ward.message}</p>
                                )}
                              </>
                            )}
                          />
                        </div>
                      </Row>
                    </>
                  )}
                </LocationViettelProvider>
              </FormProvider>
              <Button className="btn btn-success" onClick={mappingViettelPost}>Lưu Map Viettel Post</Button>
            </div>
          </div> : ''}
          <table className="table table-bordered">
            <thead>
              <tr>
                <td width={'30%'}>
                  Tên đơn vị
                </td>
                <td width={'20%'}>
                  Loại
                </td>
                <td width={'30%'}>
                  Thời gian
                </td>
                <td width={'20%'}>
                  Giá
                </td>
              </tr>
            </thead>
            <tbody>

              {listCostDelivery.filter(e => !['VIETTELPOST-WRONG_ADDRESS'].includes(e.id)).map((e) =>
                <tr>
                  <td >
                    <label style={{ display: 'flex', alignItems: 'center' }}>
                      <div>
                        <input type="radio" name="goship-delivery-type" onChange={() => {
                          setRate(e)
                        }}
                          checked={rate && e.id === rate.id}

                        />
                        {e.carrierLogo ? <>
                          <img height={40} src={e.carrierLogo} alt="logo" />
                          <br />
                        </> : ''}
                        {e.carrierName}
                      </div>
                    </label>
                  </td>
                  <td>
                    {e.service}
                  </td>
                  <td>
                    {e.expected}
                  </td>
                  <td>
                    {RenderAmount({ price: e.totalFee })}
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={`2`}>
                  <div className="d-flex">
                    <div>
                      Tổng tiền: {RenderAmount({ price: refDeliveryInfo?.current?.restTotal() })}
                    </div>
                    <div className="cod ms-5">COD:
                      <input
                        checked={!!isCod}
                        type='checkbox'
                        onClick={() => {
                          setIsCod(!isCod)
                          setRate(null)
                          getListCostDelivery(!isCod)
                        }}
                      />
                    </div>
                  </div>
                </td>
                <td colSpan={`2`} style={{ textAlign: 'right' }} className="w-100 pt-2">
                  <Button
                    style={{ marginRight: 5 }}
                    className="btn btn-success"
                    onClick={saveCostDelivery}
                    disabled={rate === null}
                  >Gửi vận chuyển</Button>
                  <Button className="btn btn-danger" onClick={() => {
                    setToggleListCostDelivery(false);
                    setStatus('INFO');
                    setRate(tempData?.rate);
                    setIsCod(tempData?.isCod);
                  }}>X</Button>

                </td>
              </tr>
            </tfoot>

          </table>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default OderManager;
