import PropTypes from "prop-types";
import React, { useRef } from "react";
import { Button } from "reactstrap";
import ReactToPrint from "react-to-print";
import ComponentToPrintOne from "./printComponentOne";
import moment from 'moment';
import { useNavigate } from "react-router";
import "./oderItem.scss";

const OderItem = (props) => {
  const navigate = useNavigate();

  const {
    id,
    province,
    district,
    ward,
    listProduct,
    name,
    phone,
    email,
    street,
    createdAt,
    total, productsAmount, vatAmount, vatPercent, costDeliver,
    note, noteInvoice, invoiceType, deposit, typeDeliver
  } = props;
  const printRef = useRef(null);
  return (
    <div className="oderItem">
      <div className="oderItem_title">
        <p>{`Đơn: ${id} - ${name || 'Ẩn Danh'} - ${phone || 'Không có SDT'}`}</p>
        <p> Ngày tạo: {moment(createdAt).format('HH:mm DD-MM-YYYY')}</p>
        <p>
          {`Địa chỉ: ${street ? `${street}, ` : ''}${ward ? `${ward}, ` : ''}${district ? `${district}, ` : ''}${province ? `${province}, ` : ''}`}
        </p>
        <div className="oderItem_table">
          <table>
            <thead>
              <tr>
                <th>Tên sản phẩm</th>
                <th>Thuộc tính</th>
                <th>Số lượng</th>
              </tr>
            </thead>
            <tbody>
              {listProduct &&
                listProduct.map((item, idx) => (
                  <tr key={idx}>
                    <td style={{ width: "70%" }}>
                      {" "}
                      <p
                        style={{
                          margin: 0,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          width: "95%",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item?.Product?.name}
                      </p>
                    </td>
                    <td>{item?.ProductAttribute?.attribute}</td>
                    <td>{item?.quantity}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="oderItem_wrapButton">
          <ReactToPrint
            trigger={() => (
              <Button
                style={{ marginRight: 5 }}
                className="btn btn-primary waves-effect waves-light"
              >
                In
              </Button>
            )}
            content={() => printRef.current}
          />
          <Button style={{ marginRight: 5 }} className="btn btn-info waves-effect waves-light" onClick={() => navigate(`/oder-manager/${id}`)}>Chi tiết đơn</Button>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <ComponentToPrintOne
          ref={printRef}
          name={name}
          email={email}
          phone={phone}
          id={id}
          province={province}
          district={district}
          ward={ward}
          street={street}
          listProduct={listProduct}
          total={total}
          productsAmount={productsAmount}
          vatAmount={vatAmount}
          vatPercent={vatPercent}
          costDeliver={costDeliver}
          createdAt={createdAt}
          note={note}
          noteInvoice={noteInvoice}
          invoiceType={invoiceType}
          deposit={deposit}
          typeDeliver={typeDeliver}
        />
      </div>
    </div>
  );
};

export default OderItem;

OderItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  street: PropTypes.string,
  phone: PropTypes.string,
  province: PropTypes.string,
  district: PropTypes.string,
  ward: PropTypes.string,
  listProduct: PropTypes.array,
  handlePrint: PropTypes.func,
  handleDelivery: PropTypes.func,
  loading: PropTypes.bool,
  total: PropTypes.number,
  productsAmount: PropTypes.number,
  vatAmount: PropTypes.number,
  costDeliver: PropTypes.number,
  createdAt: PropTypes.string,
};
