import PropTypes from "prop-types";
import React, { useImperativeHandle } from "react";
import { RenderAmount, docTienBangChu } from "../../utils";
import moment from 'moment';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { post } from "../../helpers/api_helper";
import toastr from "toastr";

const ExportExcelComponentToPrint = React.forwardRef((props, ref) => {
  const {
    id, province, district, ward, listProduct, name, phone, street,
    total, productsAmount, vatAmount, vatPercent, costDeliver, deposit, email,
    createdAt
  } = props;
  const exportToCSV = () => {
    let totalCost = 0
    const address = `${street ? `${street}, ` : ''}${ward ? `${ward}, ` : ''}${district ? `${district}, ` : ''}${province ? `${province}, ` : ''}`;
    const items = listProduct
      .map((item, i) => {
        const tempTotalCost = Number(item?.quantity || 0) * Number(item?.cost || 0)
        totalCost += tempTotalCost
        return {
          index: i + 1,
          name: item?.Product?.name || '',
          attributeTitle: item?.ProductAttribute?.attribute || '',
          externalId: item?.ProductAttribute?.externalId || '',
          quantity: item?.quantity || 0,
          costPerUnit: Number(item?.cost || 0),
          totalCost: tempTotalCost,
          pricePerUnit: Number(item?.price || 0) - Number(item.discountPerQuantity || 0),
          total: Number(item?.quantity || 0) * (Number(item?.price || 0) - Number(item.discountPerQuantity || 0))
        }
      });

    post('/bills/export-excel', {
      items,
      id,
      total,
      productsAmount,
      vatAmount,
      costDeliver,
      name,
      phone,
      email,
      address,
      vatPercent,
      totalText: docTienBangChu(total - deposit),
      totalCost,
      deposit,
      payment: total - deposit,
      createdAt: moment(createdAt).format('HH:mm DD-MM-YYYY')
    }, {
      responseType: 'blob'
    })
      .then((data) => {
        return FileSaver.saveAs(data, `hoa-don.xlsx`);
      })
      .catch(err => {
        toastr(err?.data?.error || 'Có lỗi xảy ra khi xuất excel, vui lòng thử lại sau', { appearance: 'error' })
      })

  }
  useImperativeHandle(ref, () => ({
    exportToCSV
  }))

  return <></>
});

export default ExportExcelComponentToPrint;

ExportExcelComponentToPrint.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  street: PropTypes.string,
  phone: PropTypes.string,
  province: PropTypes.string,
  district: PropTypes.string,
  ward: PropTypes.string,
  listProduct: PropTypes.array,
  total: PropTypes.number,
  productsAmount: PropTypes.number,
  vatAmount: PropTypes.number,
  costDeliver: PropTypes.number,
  createdAt: PropTypes.string,

};
