import React, { useEffect, useMemo, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { getPublic } from "../../helpers/api_helper";

const LocationProvider = ({ children }) => {
  const { watch, setValue, getValues, formState: { isDirty } } = useFormContext();
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);

  // =============Value selected=============
  const provinceWatch = watch("province");
  const districtWatch = watch("district");
  useEffect(() => {
    getPublic("/addresses/all-address").then((res) => {
      const { data } = res;
      setProvinces(data.sort((a, b) => b.id === 79 ? 1 : -1));
      const {
        province: cP,
        district: cD,
      } = getValues();
      if (cP && cD) {
        const cProvince = data.find(e => e.id === cP.value);
        setDistricts(cProvince.Districts);
        const cDistrict = cProvince.Districts.find(e => e.id === cD.value);
        setWards(cDistrict.Wards)
      }
    });
  }, []);

  // Provinces Dropdown Options
  const provinceOptions = useMemo(
    () =>
      provinces?.map((val) => ({
        label: val.name,
        value: val.id,
      }))?.sort((a, b) => (a.label > b.label ? 1 : -1)),
    [provinces]
  );

  // Districts Dropdown Options
  const districtOptions = useMemo(
    () =>
      districts?.map((val) => ({
        label: val.name,
        value: val.id,
      }))?.sort((a, b) => (a.label > b.label ? 1 : -1)),
    [districts]
  );
  // Wards Dropdown Options
  const wardOptions = useMemo(
    () =>
      wards?.map((val) => ({
        label: val.name,
        value: val.id,
      }))?.sort((a, b) => (a.label > b.label ? 1 : -1)),
    [wards]
  );

  // LISTENER OPTIONS CHANGE
  useEffect(() => {
    if (provinces.length > 0 && provinceWatch?.value) {
      const provinceFinded = provinces.find(e => e.id === provinceWatch?.value);
      setDistricts(provinceFinded.Districts);
      setWards(provinceFinded.Districts[0].Wards);
      // setValue("district", null);
      // setValue("ward", null);
    }
  }, [provinceWatch, isDirty]);

  useEffect(() => {

    // check provicne make sure api get address done
    if (provinces.length > 0 && districtWatch?.value) {
      const { Districts: districts } = provinces.find(e => e.id === provinceWatch?.value);
      const districtFinded = districts.find(e => e.id === districtWatch?.value);
      setWards(districtFinded.Wards);
      // setValue("ward", null);

    }
  }, [districtWatch, isDirty]);


  return (
    <>
      {children({
        provinceOptions,
        districtOptions,
        wardOptions,
      })}
    </>
  );
};
export default LocationProvider;
