import PropTypes from "prop-types";
import React from "react";
import ComponentToPrintOne from "./printComponentOne";

const ComponentToPrintAll = React.forwardRef((props, ref) => {
  const { listProduct } = props;
  return (
    <div ref={ref}>
      {listProduct &&
        listProduct.map((item, index) => (
          <div key={index} style={{ pageBreakAfter: "always" }}>
            <ComponentToPrintOne
              name={item.name}
              phone={item.phone}
              email={item.email}
              id={item.id}
              province={item.Province.name}
              district={item.District.name}
              ward={item.Ward.name}
              street={item.addressDeliver.street}
              listProduct={item.BillDetails}
              createdAt={item.createdAt}
              total={item.total}
              productsAmount={item.productsAmount}
              vatAmount={item.vatAmount}
              costDeliver={item.costDeliver}
              note={item.note}
              noteInvoice={item.noteInvoice}
              invoiceType={item.invoiceType}
              vatPercent={item.vatPercent}
              deposit={item.deposit}
              typeDeliver={item.typeDeliver}
            />
          </div>
        ))}
    </div>
  );
});

export default ComponentToPrintAll;

ComponentToPrintAll.propTypes = {
  listProduct: PropTypes.array,
};
